.ask4code {
    min-width: 320px;
    max-width: 1200px;
    border: 1px solid #eee;
    padding: 40px;
    border-radius: 10px;
    font-size: 20px;
    text-align: center;

    >div:first-of-type {
        margin-bottom: 40px;
    }

    >div:last-of-type {
        margin-top: 40px;
    }

    input {
        text-align: center;
        margin-bottom: 20px;
    }

    a,
    button {
        padding: 15px 30px;
        text-decoration: none;
        font-size: 20px;
        text-transform: uppercase;
        color: black;

        &.default {
            background-color: #eee;
        }

        &.success {
            background-color: green;
            color: white;
            font-weight: 500;
        }
        &[disabled].success {
            opacity: 0.5;
            background-color: #080 ;
        }
    }
}

.error {
    font-weight: 500;
    color: red;
}
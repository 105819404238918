.main {
    padding: 40px;
}

.instruction {
    max-width: 512px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 17px;
}

.menu {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;

    a,
    button {
        padding: 15px 30px;
        text-decoration: none;
        font-size: 20px;
        text-transform: uppercase;
        color: black;

        &.default {
            background-color: #eee;
        }

        &.success {
            background-color: green;
            color: white;
            font-weight: 500;
        }
    }
}


.results {
    padding: 40px;


    @media screen and (max-width:512px) {
        padding: 20px 10px;
    }

    .title {
        text-align: center;
        font-size: 30px;
        margin-bottom: 40px;
    }

    .list {
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: center;
        flex-wrap: wrap;

        @media screen and (max-width:512px) {
            gap:0 ;
        }

        >div {
            border: 15px solid #444;
            padding: 15px;
            min-width: 512px;
            min-height: 512px;
            box-sizing: content-box;
            cursor: pointer;

            &:hover {
                border: 15px solid #666;
            }

            @media screen and (max-width:512px) {
                padding: 15px 0;
                min-width: unset;
                max-width: 100vw;
                min-height: unset;
                border: none;
                border-bottom: 4px solid #444;

                img {
                    max-width: 100%;
                }
            }
        }
    }


}

.working {
    >div {
        font-size: 30px;
        color: #888;
        text-align: center;
    }

    display:flex;
    justify-content: center;
    flex-direction: column;
    gap:40px;
    align-items: center;
}

.examples {
    padding: 40px;

    @media screen and (max-width:512px) {
        padding: 20px 10px;
    }

    .text {
        text-align: center;
        font-size: 20px;
        padding-bottom: 20px;
    }

    .list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        @media screen and (max-width:1260px) {
            justify-content: center;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        >div {
            border: 15px solid #444;
            padding: 15px;
            min-width: 512px;
            min-height: 554px;
            box-sizing: content-box;

            @media screen and (max-width:512px) {
                padding: 15px 0;
                min-width: unset;
                max-width: 100vw;
                min-height: unset;
                border: none;
                border-bottom: 4px solid #444;
            }

            img {
                max-width: 100%;
            }

            >div {
                padding-top: 15px;
                text-align: center;
                font-size: 18px;
                font-weight: 500;
            }
        }
    }


}

.options {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;

    a {
        padding: 15px 30px;
        text-decoration: none;
        font-size: 20px;
        text-transform: uppercase;
        color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        &.default {
            background-color: #eee;

        }

        &.success {
            background-color: green;
            color: white;
            font-weight: 500;
        }
    }
}


.button {
    font-size: 24px;
    background-color: green ;
    padding: 20px 60px;
    cursor: pointer;
    color:white ;
    font-weight: 500 ;
    letter-spacing: 3px;

    @media screen and (max-width:360px) {
        padding: 20px 40px;
    }

    &:hover {
        background-color: rgb(28, 180, 28);
    }
}
.main {
    min-width: 320px;
    max-width: 1200px;
    border: 1px solid #eee;
    padding: 40px  ;
    border-radius: 10px;
    font-size: 20px;
    text-align: center ;
    >div:first-of-type {
        margin-bottom:40px;
    }

}
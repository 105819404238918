.main {
    flex-grow: 1;
    width:100% ;
    display:flex ;
    justify-content: center;
    align-items: center ;
    flex-direction: column;

    >div:first-of-type {
        background-color: white ;
        padding:20px ;
        max-width: 1200px;
        padding: 40px;
        font-size: 20px;
        text-align: center;
        border: 5px solid #888;
        box-sizing: border-box;

        @media screen and (max-width:360px) {
            max-width: calc(100vw - 20px);
            padding: 20px ;
        }

        >div:first-of-type {
            margin-bottom: 40px;
        }
    }
    >div:last-of-type {
        margin-top:60px;
        a,button {
            padding:15px 30px ;
            text-decoration: none ;
            font-size: 20px;
            text-transform:uppercase ;
            color:black ;

            &.default {
                background-color: #eee ;
            }
            &.success {
                background-color: green;
                color:white  ;
                font-weight: 500 ;
            }
        }        
    }
}
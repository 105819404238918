.main {
    h1 {
        text-align: center ;
    }
    div {
        font-size: 20px;
        text-align: center ;
        margin-bottom:20px;
    }
    table {
        th, td {
            font-size: 16px;
            padding: 5px;
            text-align: center ;
            border:1px solid #eee ;
            border-collapse: collapse;
        }
    }
}
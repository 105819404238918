
.main {
    width:100% ;
    height:100%;
    padding:40px;
    flex-grow: 1;
    display:flex ;
    justify-content: center;
    align-items: center ;

    .menu {
        display: flex;
        flex-direction: row  ;
        gap: 40px;
        align-items: center ;
        justify-content: center ;
        flex-wrap: wrap ;

        >a.left {
        }

        >a {
            width:calc(100vw - 20px) ;
            max-width: 600px ;
            background-color: white ;
            text-decoration: none;
            color: black;
            display: flex;
            flex-direction: column;
            align-items: center ;
            flex: 1;
            border: 5px solid #888;
            padding:10px;
            cursor: pointer;

            @media screen and (max-width:360px) {
                padding: 20px ;
            }


            &:hover {
                background-color: #f0f0f0;
                border-color: #ccc ;

            }

            >div:first-of-type {
                text-align: center;
                font-size: 20px;
                flex-grow: 1;
            }

            >div:last-of-type {
                text-align: center;
                font-size: 24px;
                margin-top:20px;
                text-transform: uppercase;

                background-color: green ;
                padding: 20px 60px;                
                color:white ;

                @media screen and (max-width:360px) {
                    padding: 20px 40px;
                }

            }
        }
    }

    
}

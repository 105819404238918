.window {
    box-sizing: content-box;
    position: relative;
    overflow: hidden;
    background-color: #ccc;
    width: 512px;
    height: 512px;
    max-width: 100vw;
}

.window>img {
    position: absolute;
    z-index: 1;
    transition: opacity 0.3s linear;
}

.frame {
    border: 3px solid yellow;
    position: absolute;
    z-index: 2;
}

.info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    padding: 5px;
    text-align: center;
    z-index: 3;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: center ;
    margin-bottom:20px;
    max-width: 100vw;

    button {
        flex:1 ;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding:15px 0 ;

        @media screen and (max-width:530px) {
            
        }
    }
}
.main {
    .bgl {
        position: absolute;
        top: 0;
        left: 0;
        z-index:-1 ;
        max-width: 50vw;
    }

    .bgr {
        position: absolute;
        top: 0;
        right: 0;
        z-index:-1 ;
        max-width: 50vw;
    }


    .simple {
        margin-top:100px;
        width: 100%;
        display: flex;
        justify-content: center;

        img {
            max-width: 100%;
            width: 600px;
        }

        padding:20px;
    }

}
.schemes {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    .title {
        font-size: 20px;
        font-weight: 400;
    }

    .list {
        padding: 10px;
        display: grid;
        column-gap: 20px;
        row-gap: 10px;
        grid-template-columns: auto auto auto auto auto;
        align-items: center;

        img {
            max-width: 64px;
        }

        @media screen and (max-width:630px) {
            grid-template-columns: auto auto auto;

            img {
                margin-top: 20px;
                max-width: 128px;
                grid-column-start: 1;
                grid-column-end: 3;
            }
        }

        @media screen and (max-width:441px) {
            grid-template-columns: auto;

            img {
                grid-column-start: unset;
                grid-column-end: unset;
            }
        }


        div {
            display: flex;

        }

        a {
            white-space: nowrap;
            padding: 5px 10px;
            background-color: #f0f0f0;
            color: black;
            text-decoration: none;
            text-transform: uppercase;

            &:hover {
                background-color: #ddd;
            }

            i {
                margin-right: 5px;
            }
        }

        button {
            white-space: nowrap;

            i {
                margin-right: 5px;
            }
        }
    }
}

.result {
    margin-bottom: 20px;
    padding: 0 10px;

    .image {
        border: 15px solid #444;
        padding: 15px;
        margin-bottom: 20px;

        &.working {
            opacity: 0.5;
        }

        img {
            max-width: 100%;
        }
    }

    input {
        width: 100%;
    }
}

.selected {
    .text {
        display:flex ;
        flex-direction: row;
        gap:10px;
        flex-wrap: wrap;
        >div {
            white-space: nowrap;
        }
    }
    font-size: 20px;
    margin-bottom: 20px;
    padding:0 20px;
    display:flex ;
    flex-direction: row;
    gap:20px;
    align-items: center ;
    button {
        font-size:16px;
        padding:5px 10px;
    }
}

.selector {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;

    >div:first-of-type {
        font-size: 20px;
        font-weight: 500;
    }

    .list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;

        div {
            min-width:300px;
            font-size: 20px;
            padding: 20px 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center ;
            border: 5px solid #888;

            cursor: pointer;

            &:hover {
                background-color: #eee;
                border: 5px solid #ccc;
            }
        }
    }
}

.submit {
    input {
        max-width: calc(100vw - 20px) ;
        width:520px;
    }
}
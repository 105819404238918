body {
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */	
}

.app {
	display:flex ;
	flex-direction: column;
	min-height: 100vh;
	align-items: center ;
}
.app-header {
	width:100% ;
	display:flex ;
	flex-direction: row;
	justify-content: center ;
    margin-bottom:40px;
}
.app-body {
	width:100% ;
	flex-grow: 1;
	display:flex ;
	flex-direction: column;
	justify-content: center ;
	align-items: center ;
}

.header {
	display:flex ;
	max-width: 1200px;
	justify-content: center ;
	> img {
		max-width:100% ;
		max-height:200px ;
	}
}

.flex-center {
	display:flex ;
	flex-direction: column;
	justify-content: center ;
	align-items: center ;
}

.p-10 {
	padding:10px;
}

.mb10 {
	margin-bottom:10px;
}

.mb20 {
	margin-bottom:20px;
}

input[type="text"] {
	font-size: 24px;
	border: 4px solid #ccc;
	padding:15px 30px ;
	width:300px;
	text-align: center ;
	outline: none ;
	&:focus {
		border:4px solid #888 ;
	}
}

button {
	border: none ;
	outline:none ;
	padding:15px 30px ;
	text-decoration: none ;
	font-size: 20px;
	text-transform:uppercase ;
	text-align: center ;

    &:hover {
        background-color: #ddd;
    }
	&[disabled] {
		cursor: not-allowed;
	}
}

button.medium {
	font-size: 16px;
	padding:5px 10px;
}

button.sm {
	font-size: 14px;
	padding:5px 10px;
}

.well {
    min-width: 320px;
    max-width: 1200px;
    border: 1px solid #eee;
    padding: 40px  ;
    border-radius: 10px;
    font-size: 20px;
    text-align: center ;
}
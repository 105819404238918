.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 20px;
    font-weight: 500;
    // position: sticky;
    // top:0;
    width: 100%;
    //background-color: white;
    text-align: center;
    z-index: 2;
    padding: 10px;
}

.axisX {
    position: sticky;
    top: 0;

    @media screen and (max-width:542px) {
        >div {
            font-size: 10px !important;
        }
    }
}

.axisY {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width:542px) {
        font-size: 10px !important;
    }
}

.pages {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: calc(100vw - 40px);
    gap: 20px;

    button {
        height: 60px;
    }

    .cards {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        gap: 20px;
        padding-bottom: 20px;
        margin-bottom: 20px;

        button.active {
            background-color: #444;
            color: white;
        }
    }
}

.block {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: auto;
    max-width: calc(100vw - 20px);

    @media screen and (max-width:542px) {
        max-width: calc(100vw - 10px);
        gap: 5px;
    }

    >div {
        display: flex;
        flex-direction: row;
        gap: 10px;

        @media screen and (max-width:866px) {
            gap: 5px;
        }

        @media screen and (max-width:542px) {
            gap: 3px;
        }


        >div {
            width: 30px;
            height: 30px;
            position: relative;
            display: flex;
            align-items: flex-end;
            font-size: 20px;
            font-weight: bold;
            padding-left: 2px;
            box-sizing: border-box;

            >div {
                font-size: 14px;
                font-weight: normal;
                position: absolute;
                top: -2px;
                right: 2px;
            }

            @media screen and (max-width:750px) {
                min-width: 25px;
                max-width: 25px;
                font-size: 18px;

                >div {
                    font-size: 11px;
                }
            }

            @media screen and (max-width:648px) {
                min-width: 20px;
                max-width: 20px;
                font-size: 16px;

                >div {
                    font-size: 10px;
                }
            }

            @media screen and (max-width:512px) {
                min-width: 16px;
                max-width: 16px;
                font-size: 14px;

                >div {
                    font-size: 8px;
                }
            }


        }
    }
}
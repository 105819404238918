.buy {
    width:100% ;
    font-size: 22px;
    background-color: #444 ;
    color:white ;
    padding: 40px 20px;
    margin-top:40px;

    @media screen and (max-width:400px) {
        padding: 20px 10px;
    }

    >div:first-of-type {
        margin-bottom: 40px;
        text-align: center;

        @media screen and (max-width:400px) {
            margin-bottom: 20px;
        }
    }

    >div:last-of-type {
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;

        a {
            min-width: 300px;
            max-width: 300px;
            flex: 1;
            color: black;
            text-align: center;

            img {
                max-width: 100%;
            }
        }
    }
}